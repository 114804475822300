import clsx from 'clsx';
import Enllac from 'components/3cat/Enllac';
import Icona from 'components/3cat/ui/Icona';
import __isEmpty from 'lodash/isEmpty';
import { useEffect, useRef, useState } from 'react';
import { FreeMode } from 'swiper';
import 'swiper/css';
import 'swiper/css/free-mode';
import { Swiper, SwiperSlide } from 'swiper/react';
import styles from './menu.module.scss';
import { itemsMenuEstatic } from './menuEstatic';

export default function MenuEstatic(props) {
    const { seccio, nombonic } = props;
    const [openExplora, setOpenExplora] = useState(false);
    const refExplora = useRef();
    const refSidemenu = useRef();
    let resizeTimeOut;

    const subseccions = itemsMenuEstatic.item.filter((i) => i.text === 'explora <span class="sr-only">més seccions</span>');

    const stylesExplora = clsx({
        [styles.menuItem]: true,
        [styles.noMobile]: true,
        [styles.selected]: subseccions[0].opcions.some((s) => s.tag_friendly === seccio),
        [styles.open]: openExplora
    });

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (!refExplora?.current?.contains(event.target)) {
                setOpenExplora(false);
            }
        };
        document.addEventListener('mousedown', handleClickOutside, { passive: true });
        document.addEventListener('touchstart', handleClickOutside, { passive: true });

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
            document.removeEventListener('touchstart', handleClickOutside);
        };
    }, [refExplora, refSidemenu]);

    const _buildOpcio = (element, i) => {
        const stylesLink = clsx({
            [styles.active]: element.tag_friendly === seccio
        });

        return (
            <li key={i}>
                <Enllac
                    className={stylesLink}
                    type={element.type}
                    id={element.id}
                    slug={nombonic}
                    slugRelacio={element.tag_friendly}
                >
                    <span>
                        {!__isEmpty(element.icona) && <Icona icona={element.icona} alt={element.icona_alt} ariaHidden={true} />}
                        {element.text}
                    </span>
                </Enllac>
            </li>
        );
    };

    const _iterateOpcions = (opcions) => {
        if (!__isEmpty(opcions)) {
            return Array.from(opcions).map((element, i) => _buildOpcio(element, i));
        }
        return <></>;
    };

    const _buildSlide = (element, i) => {
        if (__isEmpty(element.opcions)) {
            const stylesLink = clsx({
                [styles.link]: true,
                [styles.active]: element.tag_friendly === seccio || (element.text === 'explora' && subseccions.some(xxx))
            });
            return (
                <SwiperSlide key={i} tag='li' className={styles.menuItem}>
                    <Enllac
                        className={stylesLink}
                        type={element.type}
                        id={element.id}
                        slug={nombonic}
                        slugRelacio={element.tag_friendly}
                    >
                        {!__isEmpty(element.icona) && (
                            <Icona
                                className={styles.iconaMenu}
                                icona={element.icona}
                                alt={element.icona_alt}
                                mida='16'
                                inline={true}
                                ariaHidden={true}
                            />
                        )}
                        {!__isEmpty(element.text) && element.text}
                    </Enllac>
                </SwiperSlide>
            );
        } else {
            return (
                <SwiperSlide
                    key={i}
                    tag='li'
                    className={stylesExplora}
                    ref={refExplora}
                    onMouseLeave={() => {
                        setOpenExplora(false);
                    }}
                >
                    <button
                        className={styles.botoDropdown}
                        onClick={() => {
                            setOpenExplora(true);
                        }}
                        onMouseEnter={() => {
                            setOpenExplora(true);
                        }}
                    >
                        <span dangerouslySetInnerHTML={{ __html: element.text }} />
                        <Icona
                            className={styles.iconaDropdown}
                            icona='fletxa_simple_avall'
                            alt='icona fletxa avall'
                            mida='16'
                            inline={true}
                        />
                    </button>
                    <ul className={styles.dropdown}>{_iterateOpcions(element.opcions)}</ul>
                </SwiperSlide>
            );
        }
    };

    const _iterateItems = (items) => {
        return !__isEmpty(items) ? Array.from(items.item).map((element, i) => _buildSlide(element, i)) : <></>;
    };

    return (
        <>
            <Swiper
                modules={[FreeMode]}
                className={styles.menuItems + ' ' + styles.mainMenu}
                slidesPerView={'auto'}
                freeMode={true}
                tag='nav'
                wrapperTag='ul'
                breakpoints={{
                    1279: {
                        spaceBetween: 32
                    }
                }}
                updateOnWindowResize={false}
                onSwiper={(swiper) => {
                    window.addEventListener('resize', function () {
                        clearTimeout(resizeTimeOut);
                        resizeTimeOut = setTimeout(() => {
                            swiper.update();
                        }, 100);
                    });
                }}
            >
                {_iterateItems(itemsMenuEstatic)}
            </Swiper>
        </>
    );
}
